import React from 'react';
import energyImg from '../../assets/img/energy.png';
import plusEnergyImg from '../../assets/img/plus-energy.png';

const EnergyBox = ({ player }) => {
    return (
        <div className='energy-box panel' >
            <div>
                <span className='stat-icon'>&#123;A&#125;</span> : {player.actionCount}
            </div>
            <div>
                <img src={energyImg} className='stat-icon' title='Energy' /> : {player.energy}
            </div>
            <div>
                <img src={plusEnergyImg} className='stat-icon' title='Upgraded Energy' /> : {player.plusEnergy}
            </div>
        </div>
    );
};

EnergyBox.displayName = 'EnergyBox';

export default EnergyBox;
