import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-regular-svg-icons'

import PlayerName from '../Site/PlayerName';
import './PlayerStats.scss';
import CardPileLink from './CardPileLink';
import Droppable from './Droppable';
import ServerStatus from '../Navigation/ServerStatus';
import { useSelector } from 'react-redux';
import { imageUrl } from '../../util';

const PlayerStats = ({
    activePlayer,
    isMe,
    leftMode,
    manualModeEnabled,
    onCardClick,
    onDragDrop,
    onMenuItemClick,
    onPopupChange,
    onTouchMove,
    onMessagesClick,
    onMouseOut,
    onMouseOver,
    player,
    showMessages,
    side,
    size,
}) => {
    const currentGame = useSelector((state) => state.lobby.currentGame);
    const isReplay = currentGame?.isReplay;
    const { gameConnected, gameConnecting, gameResponse } = useSelector((state) => ({
        gameConnected: state.games.connected,
        gameConnecting: state.games.connecting,
        gameResponse: state.games.responseTime
    }));

    const cardPiles = player.cardPiles;

    const renderDroppableList = (source, child) => {
        return isMe ? (
            <Droppable onDragDrop={onDragDrop} source={source} manualMode={manualModeEnabled}>
                {child}
            </Droppable>
        ) : (
            child
        );
    };

    const playerAwol = player.awol ? 'awol' : '';
    let playerDisconnect = player.disconnected && !isReplay && (
        <div className='state'>
            <div className={`disconnected ${playerAwol}`}>Disconnected!</div>
        </div>
    )

    let statsClass = classNames('panel player-stats', {
        'active-player': activePlayer
    });

    const pileProps = {
        isMe,
        onMenuItemClick,
        onPopupChange,
        onTouchMove,
        manualMode: manualModeEnabled,
        onCardClick,
        onDragDrop,
        onMouseOut,
        onMouseOver,
        popupLocation: side,
        size
    };

    const draw = (
        <CardPileLink
            {...pileProps}
            cards={cardPiles.deck}
            className='draw'
            numDeckCards={player.numDeckCards}
            title='Deck'
            source='deck'
        />
    );

    const hand = (
        <CardPileLink
            {...pileProps}
            cards={cardPiles.hand}
            className='hand-popup'
            title='Hand'
            source='hand'
        />
    );

    const discard = (
        <CardPileLink
            {...pileProps}
            cards={cardPiles.discard}
            className='discard-popup'
            title='Discard'
            source='discard'
        />
    );
    const spent = (
        <CardPileLink
            {...pileProps}
            cards={cardPiles.spent}
            className='spent-popup'
            title='Spent'
            source='spent'
            numFunc={() => cardPiles.spent.reduce((total, c) => total + c.pitchValue, 0)}
        />
    );

    return (
        <div className={statsClass}>
            <div className='state'>
                <PlayerName player={player} />
            </div>
            <div className='state'>
                <img src={imageUrl('bacon')} /> {player.bacon}
            </div>

            {!isMe && <div className='state'>{renderDroppableList('hand', hand)}</div>}
            <div className='state'>{renderDroppableList('draw', draw)}</div>
            <div className='state'>{renderDroppableList('discard', discard)}</div>
            <div className='state'>{renderDroppableList('spent', spent)}</div>
            {playerDisconnect}
            {showMessages && (
                <div className='state chat-status'>
                    <div className='state'>
                        <a href='#' onClick={onMessagesClick} className='pl-1' title='Toggle chat'>
                            <FontAwesomeIcon icon={faComment}></FontAwesomeIcon>
                        </a>
                    </div>
                </div>
            )}
            {!showMessages && leftMode && !isReplay && (
                <div className='state chat-status'>
                    <ServerStatus
                        connected={gameConnected}
                        connecting={gameConnecting}
                        serverType='Server'
                        responseTime={gameResponse}
                    />
                </div>
            )}
            {!showMessages && leftMode && isReplay && <div className='state chat-status'>REPLAY</div>}
        </div>
    );
};

PlayerStats.displayName = 'PlayerStats';

export default PlayerStats;
