import React from 'react';
import AlertPanel from '../Site/AlertPanel';

const GameFormatInfo = ({ gameType: gameFormat }) => {
    switch (gameFormat) {
        case 'precon':
            return (
                <AlertPanel type='info'>
                    <strong>Pod Precon</strong> Preconstructed decks that are great for new players.
                </AlertPanel>
            );
        case 'draft':
            return (
                <AlertPanel type='info'>
                    <strong>Pod Draft</strong> Draft a custom combination of champions from a pool of 8.
                </AlertPanel>
            );
    }
};

export default GameFormatInfo;
