import React from 'react';
import CardInspector from './CardInspector';
import ActivePlayerPrompt from './ActivePlayerPrompt';
import { useSelector } from 'react-redux';
import CardLog from './CardLog';
import GameMenu from './GameMenu';
import CardLogEx from './CardLogEx';
import ReplayControls from './ReplayControls';
import classNames from 'classnames';

const Sidebar = ({
    thisPlayer,
    onCommand,
    onMouseOver,
    onMouseOut,
    onTimerExpired,
    onSettingsClick,
    onManualModeClick,
    onManualCommandsClick,
    leftMode
}) => {
    const currentGame = useSelector((state) => state.lobby.currentGame);
    const isReplay = currentGame?.isReplay;
    const manualMode = useSelector((state) => state.lobby.currentGame.manualMode);

    const logArea = thisPlayer.inspectionCard ? (
        <CardInspector card={thisPlayer.inspectionCard} />
    ) : (
        <>
            {!leftMode && (
                <div className='timer-log-area'>
                    <CardLog
                        items={currentGame.cardLog}
                        onMouseOut={onMouseOut}
                        onMouseOver={onMouseOver}
                    />
                </div>
            )}
            {leftMode && (
                <CardLogEx
                    items={currentGame.cardLog}
                    onMouseOut={onMouseOut}
                    onMouseOver={onMouseOver}
                />
            )}
        </>
    );

    const panelClass = classNames('prompt-area', 'panel', {
        manual: manualMode
    })
    return (
        <div className={panelClass}>
            {leftMode && (
                <GameMenu
                    onSettingsClick={onSettingsClick}
                    onManualModeClick={onManualModeClick}
                    onManualCommandsClick={onManualCommandsClick}
                />
            )}
            {logArea}
            <div className='inset-pane'>
                {isReplay ?
                    <ReplayControls /> :
                    <ActivePlayerPrompt
                        promptState={thisPlayer.promptState}
                        onButtonClick={onCommand}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                        onTimerExpired={onTimerExpired}
                        phase={currentGame.currentPhase}
                    />}
            </div>
        </div>
    );
};

export default Sidebar;
