import React from 'react';
import CardPile from './CardPile';
import SquishableCardPanel from './SquishableCardPanel';
import Droppable from './Droppable';
import './PlayerRow.scss';
import classNames from 'classnames';
import EnergyBox from './EnergyBox';

const PlayerRow = ({
    active,
    cardSize,
    isMe,
    hand,
    manualMode,
    onCardClick,
    onCardAltClick,
    onDragDrop,
    onMouseOut,
    onMouseOver,
    onMenuItemClick,
    player,
    side,
    purgedPile
}) => {
    const renderDroppablePile = (source, child) => {
        return isMe ? (
            <Droppable onDragDrop={onDragDrop} source={source} manualMode={manualMode}>
                {child}
            </Droppable>
        ) : (
            child
        );
    };

    let cardPileProps = {
        manualMode: manualMode,
        onCardClick: onCardClick,
        onCardAltClick: onCardAltClick,
        onDragDrop: onDragDrop,
        onMenuItemClick: onMenuItemClick,
        onMouseOut: onMouseOut,
        onMouseOver: onMouseOver,
        popupLocation: side,
        size: cardSize
    };

    let handToRender = (
        <SquishableCardPanel
            cards={hand}
            className='panel hand'
            groupVisibleCards
            manualMode={manualMode}
            maxCards={5}
            onCardClick={onCardClick}
            onMenuItemClick={onMenuItemClick}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
            rotateHeader={true}
            source='hand'
            title='Hand'
            cardSize={cardSize}
            side={side}
        />
    );

    let purged = (
        <CardPile
            className='purged'
            title='Purged'
            source='purged'
            cards={purgedPile}
            {...cardPileProps}
        />
    );

    const cn = classNames('player-home-row-container pt-1 pb-1', {
        'active-player': active
    });

    return (
        <div className={cn}>
            {/* <EnergyBox player={player} /> */}
            {renderDroppablePile('hand', handToRender)}
            {(purgedPile.length > 0 || manualMode) && renderDroppablePile('purged', purged)}
        </div>
    );
};

PlayerRow.displayName = 'PlayerRow';

export default PlayerRow;
