import React from 'react';
import CardImage from './CardImage';
import attackIcon from '../../assets/img/attack-icon.png';
import attackIconGreen from '../../assets/img/attack-icon-green.png';
import passIcon from '../../assets/img/passtime-icon.png';
import passIconGreen from '../../assets/img/passtime-icon-green.png';
import refreshIcon from '../../assets/img/refresh.png';
import refreshIconGreen from '../../assets/img/refresh-green.png';

import './CardZoom.scss';
import './Cardlog.scss';

const CardLogEx = ({ items, onMouseOut, onMouseOver }) => {
    if (!items) {
        return null;
    }

    const renderItem = (item, last = false) => {
        const actionClass = 'action-' + item.type;

        if (item.type === 'attack') {
            let attIcon = attackIcon;
            if (item.pl === 'p1') {
                attIcon = attackIconGreen;
            }
            return (
                <div key={item.id} className='log-card'>
                    <div className='x-large cardlog-icon'>
                        <img className='log-icon' title='Attack!' src={attIcon} />
                    </div>
                    <div className='log-info'>
                        <span className={item.pl}>{item.p}</span> <span className={actionClass}>attacks</span> {item.obj.name}
                    </div>
                </div>
            );
        }

        if (item.type === 'pas') {
            let ptIcon = passIcon;
            if (item.pl === 'p1') {
                ptIcon = passIconGreen;
            }
            return (
                <div key={item.id} className={'log-card '}>
                    <div className='x-large cardlog-icon'>
                        <img className='log-icon' title={item.obj.name + ' passes'} src={ptIcon} />
                    </div>
                    <div className='log-info'>
                        <span className={item.pl}>{item.obj.name}</span> <span className={actionClass}>passes time</span>
                    </div>
                </div>
            );
        }

        if (item.type === 'ref') {
            let refIcon = refreshIcon;
            if (item.pl === 'p1') {
                refIcon = refreshIconGreen;
            }
            return (
                <div key={item.id} className='log-card'>
                    <div className='x-large cardlog-icon'>
                        <img className='log-icon' title={item.obj.name + ' meditates'} src={refIcon} />
                    </div>
                    <div className='log-info'>
                        <span className={item.pl}>{item.obj.name}</span> <span className={actionClass}>chooses to refresh</span>
                    </div>
                </div>
            );
        }

        // now it's a card
        if (!item.obj.id) return '';

        const actionText = item.type === 'play' ? 'plays' : 'uses';

        return (
            <div
                key={item.id}
                className='log-card'
                onMouseOut={() => onMouseOut && onMouseOut(item.obj)}
                onMouseOver={() => onMouseOver && onMouseOver(item.obj)}
            >
                <CardImage card={item.obj} noIndex={true} />
                <div className='log-info'><span className={item.pl}>{item.p}</span> <span className={actionClass}>{actionText}</span>  {item.obj.name}
                </div>
            </div>
        );
    };

    return <div className='cardlogex-wrapper'>{items.map((i) => renderItem(i))}</div>;
};

CardLogEx.displayName = 'CardLog';

export default CardLogEx;
