export function receiveUsers(users) {
    return {
        type: 'RECEIVE_USERS',
        users: users
    };
}

export function receiveLobbyMessage(message) {
    return {
        type: 'RECEIVE_LOBBY_MSG',
        message: message
    };
}

export function receiveLobbyMessages(messages) {
    return {
        type: 'RECEIVE_LOBBY_MSGS',
        messages: messages
    };
}

export function removeLobbyMessage(messageId) {
    return {
        types: ['REMOVE_MESSAGE', 'MESSAGE_REMOVED'],
        shouldCallAPI: () => true,
        APIParams: {
            url: `/api/messages/${messageId}`,
            cache: false,
            type: 'DELETE'
        }
    };
}

export function getAllPairings() {
    return {
        types: ['REQUEST_ALL_PAIRINGS', 'RECEIVE_ALL_PAIRINGS'],
        shouldCallAPI: () => true,
        APIParams: {
            url: `/api/league/pairings`,
            cache: false
        }
    };
}

export function replayStepForward() {
    return {
        type: 'REPLAY_FORWARD'
    };
}

export function replayStepBack() {
    return {
        type: 'REPLAY_BACK'
    };
}

export function replayTurnForward() {
    return {
        type: 'REPLAY_TURN_FORWARD'
    };
}
export function replayRoundForward() {
    return {
        type: 'REPLAY_ROUND_FORWARD'
    };
}
export function replayTurnBack() {
    return {
        type: 'REPLAY_TURN_BACK'
    };
}
export function replayRoundBack() {
    return {
        type: 'REPLAY_ROUND_BACK'
    };
}
