import React from 'react';
import { imageUrl } from '../../util';
import './StatusCondition.scss';

const StatusCondition = ({ status, onMouseOver, onMouseOut, className, onClick }) => {
    const imgPath = imageUrl(status.statusType);
    return (
        <div className={className} title={status.statusType}
            onMouseOver={() => onMouseOver({ id: status.statusType, imageStub: status.statusType, status: true })}
            onMouseOut={onMouseOut}
            onClick={(event) => onClick(event, status)}
        >
            {/* <Zoomable key={status.statusType} card={{ imageStub: status.statusType }}> */}
            <img className='status-image' src={imgPath} />
            {/* </Zoomable> */}
        </div>
    )
};

StatusCondition.displayName = 'StatusCondition';

export default StatusCondition;
