import React from 'react';
import { useSelector } from 'react-redux';
import './CardsPage.scss';
import CardGallery from '../Components/Profile/CardGallery';

const CardsPage = () => {
    const allCards = useSelector((state) => state.cards.cards);
    const cardList = Object.values(allCards);
    //.sort((a, b) => a.type + a.stub > b.type + b.stub ? 1 : -1);
    return (
        <div className='full-height'>
            <div className='lobby-card'>
                <div className='lobby-header'>All Cards</div>
                <div className='card-browser'>
                    <CardGallery cards={cardList} />
                </div>
            </div>
        </div>
    );
};

CardsPage.displayName = 'Decks';

export default CardsPage;
