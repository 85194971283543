import React from 'react';
import { useDispatch } from 'react-redux';
import { sendGameMessage } from '../../redux/actions';
import Panel from '../Site/Panel';
import CardImage from './CardImage';
import './WinLoseSplash.scss';

const ChampionDefeatSplash = ({ player }) => {
    const dispatch = useDispatch();

    const onCloseClick = () => {
        dispatch(sendGameMessage('menuButton', 'done', player.promptState.buttons[0].uuid));
    };

    const card = player.promptState.champion;
    const headerMessage = card.name + ' ' + 'is Defeated!';
    const baconMessage = player.promptState.playerName + ' gains ' + card.baconValue + ' bacon!';
    // const winner = game.players[game.winner];
    return (
        <Panel title='Champion Defeat' cardClass={`splash`} onCloseClick={onCloseClick}>
            <div className='central'>
                <h2 className='lobby-header'>{headerMessage}</h2>
                <div className='splash-card-wrapper'>
                    <div className='splash-card'>
                        <CardImage card={card} />
                    </div>
                </div>
                <p>{baconMessage}</p>
                <div className='buttonDiv'>
                    <button
                        onClick={onCloseClick}
                        className='btn splash-button btn-primary'
                    >Close</button>
                </div>
            </div >
        </Panel >
    );
};

export default ChampionDefeatSplash;
