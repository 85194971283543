import React from 'react';
import Card from './Card';
import classNames from 'classnames';

import './PlayerRow.scss';
import EnergyBox from './EnergyBox';

const PlayerPBRow = ({
    active,
    cardSize,
    onCardClick,
    onCardAltClick,
    onMouseOver,
    onMouseOut,
    side,
    champions,
    onMenuItemClick,
    player
}) => {
    const cn = classNames('champion-row pt-1 pb-1', {
        'active-player': active
    });

    return (
        <div className='player-home-row-container'>
            <div className={classNames('energy-wrapper', side)}>
                <EnergyBox player={player} />
            </div>
            <div className={cn}>
                {champions.map(pb => (
                    <Card
                        key={pb.id}
                        card={pb}
                        onAltClick={onCardAltClick}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                        onMenuItemClick={onMenuItemClick}
                        onClick={onCardClick}
                        size={cardSize}
                        side={side}
                        source='play area'
                    />
                ))}
            </div>
        </div>
    );
};

export default PlayerPBRow;
