import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendSocketMessage } from '../../redux/actions';
import { Button, Col, Form } from 'react-bootstrap';
import classNames from 'classnames';

import './PendingGamePlayer.scss';
import DeckStatus from '../Decks/DeckStatus';
import PlayerName from '../Site/PlayerName';
import SelectDeckModal from './SelectDeckModal';
import { PatreonStatus } from '../../types';
import { patreonUrl } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

/**
 * @typedef PendingGamePlayersProps
 * @property {PendingGame} currentGame The current pending game
 * @property {User} user The logged in user
 * @property {function(): void} onSelectDeck The callback to be invoked when a deck selection is requested
 */

/**
 * @param {PendingGamePlayersProps} props
 */
const PendingGamePlayers = ({ currentGame, user }) => {
    const [showModal, setShowModal] = useState(false);
    const [playerIsMe, setPlayerIsMe] = useState(true);
    const dispatch = useDispatch();
    const allowPremium = user?.patreon === PatreonStatus.Pledged || user?.permissions.isSupporter;
    const unlinked = !user?.patreon || user?.patreon === PatreonStatus.Unlinked;
    const showPatreonAdvice = !allowPremium;
    const isSolo = currentGame.solo;

    let firstPlayer = true;
    // need to account for coaloff, and player index
    let clickHandler = (playerIsMe) => {
        if (currentGame.gameFormat === 'coaloff') {
            return true;
        }

        setPlayerIsMe(playerIsMe);
        setShowModal(true);
    };

    const deckSelectedHandler = (deck) => {
        setShowModal(false);
        dispatch(
            sendSocketMessage('selectdeck', currentGame.id, playerIsMe, deck._id, !!deck.precon_id)
        );
    };

    const chooseForMeHandler = (deckType) => {
        // guard for unsubscribed user getting access to a random premium deck
        if (!playerIsMe && isSolo && !allowPremium) {
            return;
        }
        setShowModal(false);
        dispatch(sendSocketMessage('selectdeck', currentGame.id, playerIsMe, -1, 0, deckType));
    };

    const doDraft = () => {
        if (!currentGame.drafting) {
            dispatch(sendSocketMessage('draft', currentGame.id));
        }
    };

    const patreonLoginClick = (event) => {
        if (user?.patreon === 'linked') {
            window.location = 'https://www.patreon.com/ashteki';
        } else {
            window.location = patreonUrl;
        }

        event.preventDefault();
    };

    return (
        <div>
            <h3>Players:</h3>
            {Object.values(currentGame.players).map((player) => {
                const isMe = player && player.name === user?.username;

                let deck = null;
                let selectLink = null;
                let status = null;

                let clickClasses = classNames('deck-selection', {
                    clickable: currentGame.gameFormat !== 'coaloff'
                });

                if (player && player.deck && player.deck.selected) {
                    if (isMe || currentGame.solo) {
                        const deckName = player.deck.name;
                        deck = (
                            <button className={clickClasses} title='Select Deck' onClick={() => clickHandler(isMe)}>
                                {deckName}
                            </button>
                        );
                    } else {
                        const deckName =
                            currentGame.gameFormat === 'firstadventure'
                                ? player.deck.name
                                : 'Deck Selected';
                        deck = <span className='deck-selection'>{deckName}</span>;
                    }

                    status = !(currentGame.solo && !isMe) && (
                        <DeckStatus
                            status={player.deck.status}
                        />
                    );
                } else if (player && isMe) {
                    if (currentGame.gameFormat === 'draft') {
                        selectLink = (
                            <Button onClick={() => doDraft()} disabled={currentGame.drafting} className='btn-focus def'>
                                Pod Draft
                            </Button>
                        );
                    } else {
                        selectLink = (
                            <Button onClick={() => clickHandler(isMe)} className='btn-focus def'>
                                Select Deck
                            </Button>
                        );
                    }
                }

                let rowClass = 'player-row';
                if (firstPlayer) {
                    rowClass += ' mb-2';

                    firstPlayer = false;
                }
                return (
                    <div className={rowClass} key={player.name}>
                        <Form.Row>
                            <PlayerName player={player} />
                            {deck}
                            {status}
                            {selectLink}
                        </Form.Row>
                        {player.deck.isChimera && showPatreonAdvice && (
                            <Form.Row className='patreon-row'>
                                <div className='pending-player-name'></div>
                                <div>
                                    <span className='unlock-advice'>
                                        <a href='#' onClick={patreonLoginClick}>
                                            {unlinked ? 'Login to' : 'Support on'} Patreon
                                        </a>{' '}
                                        to unlock all content <FontAwesomeIcon icon={faCircleInfo} title='You may need to reload this page after' />
                                    </span>
                                </div>
                            </Form.Row>
                        )}
                    </div>
                );
            })}
            {showModal && (
                <SelectDeckModal
                    gameFormat={currentGame.gameFormat}
                    onClose={() => setShowModal(false)}
                    onDeckSelected={deckSelectedHandler}
                    onChooseForMe={chooseForMeHandler}
                    playerIsMe={playerIsMe}
                />
            )}
        </div>
    );
};

export default PendingGamePlayers;
